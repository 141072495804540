
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import router from "@/router";
 import { useRoute } from "vue-router"


export default defineComponent({
  name: "App",
  data() {
    return {
      home: { icon: "pi pi-home", to: "/" },
      lista: [{ label: computed(() => router.currentRoute.value.name) }],
      image: {
        backgroundImage: "url(" + require("../public/img/bg-site.jpg") + ")",
      },
      items: [
        {
          label: "Agendamento",
          icon: "pi pi-fw pi-calendar",
          to: "/agendamento",
        },
        {
          label: "Acompanhar",
          icon: "pi pi-fw pi-bookmark",
          to: "/acompanhar",
        },
        // {
        //   label: "Cancelamento",
        //   icon: "pi pi-fw pi-times-circle",
        //   to: "/cancelamento",
        // },
      ],
    };
  },
  setup() {
    
    const store = useStore();
    const route = useRoute(); // Obter informações da rota atual

    return {
        isLoading: computed(() => store.state.loading && route.path !== "/"),
    };
},

  created() {
    // Verificar se o aplicativo está em modo de manutenção
    const emManutencao = false; // substitua por sua lógica de verificação de manutenção

    if (emManutencao) {
      this.$router.push({ name: "Manutencao" });
    }
  },
});
