<template>
    <div class="access-validation">
        <Card class="shadow-5 validation-card">
            <template #title>
                <h2 class="h2-restrito">Bem-vindo(a) ao agendamento de visitas ao Universitário por um dia!</h2>
            </template>
            <template #content>
                <p class="texto-info-restrito">
                    
                   

                 Durante o 1º semestre de 2025 as visitas serão dedicadas <u>exclusivamente</u> às Diretorias Regionais de Ensino.
<br><br>
 Para as escolas, tanto públicas quanto da rede particular, o agendamento de visitas para o segundo semestre será liberado a partir de maio/2025. Acompanhe nosso <a href="https://www.instagram.com/vemsaber.usp/" target="_blank">Instagram</a> para receber todas as atualizações do Programa Vem Saber.
<br><br>
Se você é responsável por uma Diretoria Regional de Ensino e pretende agendar sua visita para o primeiro semestre, acesse o site inserindo o código de convite no campo abaixo.
<br>
<br></p>
                <div class="input-section">
                    <InputText
                        v-model="accessCode"
                        placeholder="Digite o código de acesso"
                        class="w-full"
                    />
                    <Button
                        label="Validar"
                        icon="pi pi-check"
                        class="mt-3"
                        @click="validateAccessCode"
                    />
                    <p v-if="accessError" class="p-error mt-2">
                        Código incorreto! Tente novamente.
                    </p>
                </div>
<br><br>
                Quaisquer dúvidas entre em contato conosco: <br>
e-mail: <a href="mailto:vemsaber@usp.br" target="_blank">vemsaber@usp.br </a><br>
telefone/whatsapp: <a href="https://api.whatsapp.com/send?phone=551633648282" target="_blank">16-3364-8282</a>
<br>
<strong>Equipe Vem Saber</strong>
            </template>
        </Card>
    </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
    name: "AccessValidation",
    setup() {
        const accessCode = ref(""); // Código digitado pelo usuário
        const accessError = ref(false); // Exibe erro se o código for inválido
        const router = useRouter(); // Instância do roteador para navegação

        const validateAccessCode = () => {
            if (accessCode.value === "UPD2025") {
                sessionStorage.setItem("isAuthorized", "true"); // Salva a autorização
                // Redireciona para a página de agendamento
                router.push("/agendamento");
            } else {
                accessError.value = true; // Mostra erro se o código for incorreto
                accessCode.value = ""; // Limpa o campo de entrada
            }
        };

        return {
            accessCode,
            accessError,
            validateAccessCode,
        };
    },
};
</script>

<style>

.h2-restrito{
    margin: 0;
}

.p-card-content{
    padding: 0 !important; 
}

.texto-info-restrito{
 font-size: 20px;
 text-align: justify;
}




.access-validation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
}

.validation-card {
    max-width: 800px;
    width: 90%;
    text-align: center;
    padding: 20px;
}

.input-section {
    margin-top: 20px;
}

.p-error {
    color: #f44336;
    font-weight: bold;
}
</style>
